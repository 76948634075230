.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s linear, opacity 0.15s ease-in 0.1s;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s ease-in, visibility 0.1s linear 0.2s;
}

.container p:last-child {
  border-radius: 0 0 var(--space-size-1) var(--space-size-1);
}
