.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  // width: 100%;
  z-index: 3;
  padding: var(--space-size-1) var(--space-size-1) 0rem var(--space-size-1);

  &.noPadding {
    padding: 0;
  }
}
