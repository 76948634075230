@import 'styles/utils/mixins';
@import 'styles/core/typography';
.navigation {
  margin-left: 2.4rem;
  display: none;

  @media screen and (min-width: $breakpoint-sm) {
    height: 100%;
    margin-left: 2rem;
  }
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 2 / span 9;
  }
  @media screen and (min-width: $breakpoint-lg) {
    grid-column: 3 / span 8;
    margin-left: 0rem;
    display: block;
  }
  @media screen and (min-width: $breakpoint-xl) {
    width: 100%;
    margin-left: 0rem;
  }
}
.list {
  display: flex;
  align-items: center;
  height: 100%;
  &:hover > .itemDisable:not(:hover) {
    color: var(--color-on-bg-secondary);
    .anchorlink {
      color: var(--color-on-bg-secondary);
    }
    &::before {
      height: 0%;
      transition: height 0.2s;
    }
  }
  & > .itemActive ~ .itemDisable:not(:hover) {
    color: var(--color-on-bg-secondary);
    .anchorlink {
      color: var(--color-on-bg-secondary);
    }
    &::before {
      height: 0%;
      transition: height 0.2s;
    }
  }
  & > .itemActive {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
    &::before {
      height: 2.6%;
      transition: height 0.2s;
    }
  }

  & > .itemDisable:hover {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
    &::before {
      height: 2.6%;
      transition: height 0.2s;
    }
  }

  & > .itemFadeLine {
    &::before {
      background: transparent;
      transition-duration: background 0.2s;
    }
  }

  & > .itemRouterIsActive {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
    &::before {
      height: 2.6%;
      transition: height 0.2s;
    }
  }
  & > .itemRouterIsDisable {
    color: var(--color-on-bg-secondary);
    .anchorlink {
      color: var(--color-on-bg-secondary);
    }
  }
  & > .itemIsInHome {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
  }
}
.item {
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
  .anchorlink {
    text-decoration: none;

    @media screen and (min-width: $breakpoint-md) {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    margin-right: 2rem;
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-right: 4rem;
  }
  @include label-l-medium;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    transition: height 0.2s;
    background-color: var(--color-primary);
  }
  color: var(--color-on-bg-primary);
  .anchorlink {
    color: var(--color-on-bg-primary);
  }
  transition: color 0.2s;
  &::before {
    height: 0%;
    transition-duration: 0.2s;
  }
}

.buttonItem {
  position: relative;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
  @include label-m-medium;

  @media screen and (min-width: $breakpoint-lg) {
    @include label-l-medium;
  }
}
.arrow {
  height: 0.8rem;
  width: 1.6rem;
  margin-left: auto;
  margin-left: 0.8rem;
}
.arrowUp {
  transition-duration: 0.2s;
  transform: rotate(180deg);
}
.arrowDown {
  transform: rotate(0deg);
  transition-duration: 0.2s;
}
