@import 'styles/core/typography';
@import 'styles/utils/shared';

.container {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include label-xs-bold;
  text-transform: uppercase;
  color: var(--color-bg);
  background-color: var(--color-accent);
  margin: 0 var(--space-size-1) 0 0;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s linear, opacity 0.15s ease-in 0.1s;

  @media screen and (min-width: $breakpoint-lg) {
    width: 3.4rem;
    height: 3.4rem;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s ease-in, visibility 0.1s linear 0.2s;
}
