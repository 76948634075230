// buttons
@mixin button($bgColor, $color) {
  background: $bgColor;
  text-transform: uppercase;
  border-radius: 0.4rem;
  color: $color;
  text-decoration: none;
  cursor: pointer;
}
@mixin button-normal {
  padding: 1.6rem 3.2rem 1.6rem;
  max-height: 4.8rem;
  @include label-m-bold;
}

@mixin button-medium {
  padding: 1.2rem 3.2rem 1.2rem;
  max-height: 4rem;
  @include label-m-bold;
}

@mixin button-small {
  padding: 0.8rem 1.6rem 0.8rem;
  max-height: 2.8rem;
  @include label-xs-bold;
}

@mixin button-xsmall {
  padding: 0.4rem 1.4rem 0.4rem !important;
  max-height: 2.2rem;
  @include label-xs-bold;
}

// button-primary
@mixin button-primary {
  @include button(var(--color-primary), var(--color-bg));
  &:hover,
  &:focus {
    background-color: var(--color-primary-hover);
  }
  &:active {
    background-color: var(--color-primary-press);
  }
}
@mixin button-primary-normal {
  @include button-primary;
  @include button-normal;
}
@mixin button-primary-medium {
  @include button-primary;
  @include button-medium;
}
@mixin button-primary-small {
  @include button-primary;
  @include button-small;
}

// button-secondary
@mixin button-secondary {
  @include button(var(--color-on-bg-primary), var(--color-surface-01));
}
@mixin button-secondary-normal {
  @include button-secondary;
  @include button-normal;
}
@mixin button-secondary-medium {
  @include button-secondary;
  @include button-medium;
}
@mixin button-secondary-small {
  @include button-secondary;
  @include button-small;
}

// button-tertiary
@mixin button-tertiary {
  @include button(var(--color-surface-03), var(--color-on-bg-primary));
}
@mixin button-tertiary-normal {
  @include button-tertiary;
  @include button-normal;
}
@mixin button-tertiary-medium {
  @include button-tertiary;
  @include button-medium;
}
@mixin button-tertiary-small {
  @include button-tertiary;
  @include button-small;
}

// button small
@mixin button-black {
  @include button(var(--color-bg), var(--color-neutral-01));
}

@mixin button-black-normal {
  @include button-black;
  @include button-normal;
}
@mixin button-black-medium {
  @include button-black;
  @include button-medium;
}
@mixin button-black-small {
  @include button-black;
  @include button-small;
}

@mixin button-quota {
  @include button(var(--color-surface-01), var(--color-on-bg-primary));
  @include label-l-medium;

  span {
    color: var(--color-on-bg-secondary);
    margin-right: 1rem;
    @include label-s-medium;
  }
}
