@import 'styles/utils/shared';

.container {
  border-radius: var(--space-size-1);
  min-height: 19rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 var(--space-size-1);
  height: auto;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  z-index: 0;
  aspect-ratio: 0.8;
}

.imgContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: var(--space-size-1);
}
.imgHasLinearGradient {
  &::before {
    content: '';
    border-radius: var(--space-size-1);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(45deg, #0000 0% 60%, rgba(0, 0, 0, 60%) 90%);
  }
}

.image {
  border-radius: var(--space-size-1);
  object-fit: cover;
  aspect-ratio: 0.8;
}

.linked {
  cursor: pointer;
}

.button {
  width: 100%;
}
