.container {
  max-width: 192rem;
  margin: 0 auto;

  &.fullWidth {
    max-width: 100%;
  }
}

.isLoadingVendita {
  pointer-events: none;
}

.containerLogo {
  z-index: -1;
}

.logo {
  z-index: -1;
}
