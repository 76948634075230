@import 'styles/utils/shared';
@import 'styles/core/buttons';
@import 'styles/core/typography';

.container {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--space-size-1);
  transition: opacity 0.15s ease-in, visibility 0.1s linear 0.2s;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.1s linear, opacity 0.15s ease-in 0.1s;
  }
}

.link {
  @include button-primary-medium;
  z-index: 2;
  cursor: pointer;

  @media screen and (min-width: $breakpoint-lg) {
    @include button-primary-normal;
  }
}
