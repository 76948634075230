@import '../utils/functions';
@import '../utils/shared';

//heading xxl
@mixin heading-xxl {
  font-size: 4rem;
  letter-spacing: 0;
  line-height: lh-unit-less(40, 42);
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 8.8rem;
    line-height: lh-unit-less(88, 90);
  }
}
@mixin heading-xxl-light {
  @include heading-xxl;
  font-weight: 300;
}
@mixin heading-xxl-medium {
  @include heading-xxl;
  font-weight: 500;
}

//heading xl
@mixin heading-xl {
  font-size: 2.4rem;
  letter-spacing: 0;
  line-height: lh-unit-less(24, 32);
  @media screen and (min-width: $breakpoint-sm) {
    font-size: 3.2rem;
    line-height: lh-unit-less(32, 38);
  }
  @media screen and (min-width: $breakpoint-md) {
    font-size: 4rem;
    line-height: lh-unit-less(40, 48);
  }
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 4.8rem;
    line-height: lh-unit-less(48, 56);
  }
  @media screen and (min-width: $breakpoint-xl) {
    font-size: 5.6rem;
    line-height: lh-unit-less(56, 64);
  }
}
@mixin heading-xl-medium {
  @include heading-xl;
  font-weight: 500;
}
@mixin heading-xl-bold {
  @include heading-xl;
  font-weight: 700;
}

//heading l
@mixin heading-l {
  font-size: 2.2rem;
  letter-spacing: 0.04em;
  line-height: lh-unit-less(22, 28);
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 4rem;
    line-height: lh-unit-less(40, 48);
  }
}
@mixin heading-l-medium {
  @include heading-l;
  font-weight: 500;
}

// heading m
@mixin heading-m {
  font-size: 2rem;
  letter-spacing: 0.04em;
  line-height: lh-unit-less(20, 24);
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 3.2rem;
    line-height: lh-unit-less(32, 38);
  }
}
@mixin heading-m-light {
  @include heading-m;
  font-weight: 300;
}
@mixin heading-m-medium {
  @include heading-m;
  font-weight: 500;
}
@mixin heading-m-bold {
  @include heading-m;
  font-weight: 700;
}

//heading s
@mixin heading-s {
  font-size: 1.8rem;
  letter-spacing: 0.04em;
  line-height: lh-unit-less(18, 24);
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 2.4rem;
    line-height: lh-unit-less(24, 32);
  }
}
@mixin heading-s-medium {
  @include heading-s;
  font-weight: 500;
}
@mixin heading-s-bold {
  @include heading-s;
  font-weight: 700;
}

//body
@mixin body {
  font-size: 1.6rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(16, 22);
}
@mixin body-medium {
  @include body;
  font-weight: 500;
}
@mixin body-bold {
  @include body;
  font-weight: 700;
}

//body s
@mixin body-s {
  font-size: 1.4rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(14, 16);
}
@mixin body-s-light {
  @include body-s;
  font-weight: 300;
}
@mixin body-s-medium {
  @include body-s;
  font-weight: 500;
}
@mixin body-s-bold {
  @include body-s;
  font-weight: 700;
}

//body xs
@mixin body-xs {
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(12, 16);
  @media screen and (min-width: $breakpoint-lg) {
    font-size: 1.2rem;
    line-height: lh-unit-less(14, 16);
  }
}
@mixin body-xs-medium {
  @include body-xs;
  font-weight: 500;
}
@mixin body-xs-bold {
  @include body-xs;
  font-weight: 700;
}

//label l
@mixin label-l {
  font-size: 1.6rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(16, 18);
  text-transform: uppercase;
}
@mixin label-l-medium {
  @include label-l;
  font-weight: 500;
}
@mixin label-l-bold {
  @include label-l;
  font-weight: 700;
}

//label m
@mixin label-m {
  font-size: 1.4rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(14, 16);
  text-transform: uppercase;
}
@mixin label-m-medium {
  @include label-m;
  font-weight: 500;
}
@mixin label-m-bold {
  @include label-m;
  font-weight: 700;
}

//label s
@mixin label-s {
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(12, 14);
  text-transform: uppercase;
}
@mixin label-s-medium {
  @include label-s;
  font-weight: 500;
}
@mixin label-s-bold {
  @include label-s;
  font-weight: 700;
}
// label-xs
@mixin label-xs {
  font-size: 1rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(10, 12);
  text-transform: uppercase;
}
@mixin label-xs-medium {
  @include label-xs;
  font-weight: 500;
}
@mixin label-xs-bold {
  @include label-xs;
  font-weight: 700;
}

// label-xxs
@mixin label-xxs {
  font-size: 0.8rem;
  letter-spacing: 0.06em;
  line-height: lh-unit-less(8, 12);
  text-transform: uppercase;
  font-variant-ligatures: none;
}
@mixin label-xxs-medium {
  @include label-xxs;
  font-weight: 500;
}
@mixin label-xxs-bold {
  @include label-xxs;
  font-weight: 700;
}
