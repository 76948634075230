@import 'styles/utils/mixins';
@import 'styles/core/typography';

.detailsMenu {
  z-index: 3;
  min-width: 18rem;
  position: absolute;
  height: fit-content;

  @media screen and (min-width: $breakpoint-md) {
    max-width: 18rem;
    margin: 0 auto;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: 100%;
  }
}
.hide {
  height: 0rem;
  overflow-y: hidden;
  transition: height 0.3s ease-in;

  .item {
    opacity: 0;
    transform: translateY(-3rem);
  }
}
.show {
  overflow-y: visible;
  height: 100%;
  transition: height 0.3s ease-in;

  border-top: 0.2rem solid var(--color-secondary);
  .item {
    opacity: 1;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-top: 2.4rem;
    transform: translateY(0rem);
    cursor: pointer;
    &:last-of-type {
      padding-bottom: 2.4rem;
    }
  }
}

.list {
  background-color: var(--color-surface-02);
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom: 0.4rem solid var(--color-primary);
}
.sectionList {
  padding-top: 4.8rem;
}
.itemActive {
  color: var(--color-on-bg-primary);
  a {
    color: var(--color-on-bg-primary);
  }
}
.itemInactive {
  color: var(--color-on-bg-secondary);
  a {
    color: var(--color-on-bg-secondary);
  }
}
.itemDefault {
  color: var(--color-on-bg-primary);

  a {
    color: var(--color-on-bg-primary);
  }
}
.link {
  @include body-medium;
  text-transform: capitalize;
  text-decoration: none;
  display: block;
}
