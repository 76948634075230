@import 'styles/core/typography';
@import 'styles/utils/mixins';

.title {
  @include label-l-bold;
  @include truncate-text(2);
  color: var(--color-on-bg-primary);
  margin: 0 0 var(--space-size-0-5) 0;
  text-transform: uppercase;
  text-decoration: none;
}
