@import 'styles/utils/mixins';
@import 'styles/core/typography';

.container {
  background-color: var(--color-secondary);
  padding: var(--space-size-1-5);

  @media screen and (min-width: $breakpoint-md) {
    padding: 2rem var(--space-size-5);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.scrolledUp {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in, opacity 0s ease-in 0.3s;
    opacity: 0;
  }

  &.scrolledDown {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.3s ease-in;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.text {
  color: var(--color-neutral-01);
  @include body-xs-medium;

  @media screen and (min-width: $breakpoint-md) {
    white-space: nowrap;
  }
}

.imgContainer {
  display: flex;
  position: relative;
  height: var(--space-size-2);
  aspect-ratio: 5.8;
  flex: 0 0 9.342rem;
  margin: 0 var(--space-size-2) 0 0;
}

.link {
  display: none;
  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    @include body-xs-medium;
    color: var(--color-on-bg-primary);
    text-decoration: underline;
    cursor: pointer;
  }
}

.btnClose {
  display: flex;
  cursor: pointer;
  span[hidden] {
    display: none;
  }
  @media screen and (max-width: $breakpoint-sm) {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  @media screen and (min-width: $breakpoint-sm) {
    margin-right: 0rem;

    margin-left: 2.4rem;
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-left: 2rem;
  }
}
.iconClose {
  width: 1.4rem;
  height: 1.4rem;
}
