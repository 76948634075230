@import 'styles/utils/mixins';
@import 'styles/core/typography';
/* .navigation {
  margin-left: 2.4rem;
  display: none;

  @media screen and (min-width: $breakpoint-sm) {
    height: 100%;
    margin-left: 2rem;
  }
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 2 / span 9;
    display: block;
  }
  @media screen and (min-width: $breakpoint-lg) {
    grid-column: 3 / span 8;
    margin-left: 0rem;
  }
  @media screen and (min-width: $breakpoint-xl) {
    width: 100%;
    margin-left: 0rem;
  }
} */

.buttonsListContainer {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  order: 3;
  margin-left: auto;

  @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
    order: unset;
    position: absolute;
    right: 0;
    top: 0;
    bottom: unset;
  }
  @media screen and (min-width: $breakpoint-xl) {
    grid-column: 11 / span 2;
  }
}
.buttonsList {
  display: flex;
  align-items: center;

  margin-right: unset;
  height: 100%;
  border-left: 0.1rem solid var(--color-divider-primary);
}
.btnLinkContainer {
  position: relative;
}

.isDisable:hover {
  &::before {
    height: 4%;
    transition: height 0.2s;
  }
}

.btnLinkContainer:has(> .isDisable:hover) ~ .btnLinkContainer > .isActive {
  &::before {
    height: 0% !important;
    transition: height 0.2s !important;
  }
}

.isActive {
  &::before {
    height: 4% !important;
    transition: height 0.2s !important;
  }
}

.btnLink {
  height: 100%;
  background-color: transparent;
  color: var(--color-on-bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  padding: 0 2rem;
  cursor: pointer;
  border-left: 0.1rem solid var(--color-divider-primary);
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    transition: height 0.2s;
    background-color: var(--color-primary);
  }

  &:hover,
  &:focus {
    transition: background-color 0.2s;
    background-color: var(--color-bg);
  }
  // &:hover {
  //   &::before {
  //     height: 4%;
  //     transition: height 0.2s;
  //   }
  // }
  @media screen and (min-width: $breakpoint-sm) {
    padding: 0 2rem;
    min-width: auto;

    @media screen and (min-width: $breakpoint-lg) {
      padding: 0 var(--space-size-4);
    }
  }

  // &.isActive {
  //   &::before {
  //     height: 4%;
  //     transition: height 0.2s;
  //   }
  // }
}
.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.list {
  display: flex;
  align-items: center;
  height: 100%;
  &:hover > .itemDisable:not(:hover) {
    color: var(--color-on-bg-secondary);
    .anchorlink {
      color: var(--color-on-bg-secondary);
    }
    &::before {
      height: 0%;
      transition: height 0.2s;
    }
  }
  & > .itemActive ~ .itemDisable:not(:hover) {
    color: var(--color-on-bg-secondary);
    .anchorlink {
      color: var(--color-on-bg-secondary);
    }
    &::before {
      height: 0%;
      transition: height 0.2s;
    }
  }
  & > .itemActive {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
    &::before {
      height: 4%;
      transition: height 0.2s;
    }
  }

  & > .itemDisable:hover {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
    &::before {
      height: 4%;
      transition: height 0.2s;
    }
  }

  & > .itemFadeLine {
    &::before {
      background: transparent;
      transition-duration: background 0.2s;
    }
  }

  & > .itemRouterIsActive {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
    &::before {
      height: 4%;
      transition: height 0.2s;
    }
  }
  & > .itemRouterIsDisable {
    color: var(--color-on-bg-secondary);
    .anchorlink {
      color: var(--color-on-bg-secondary);
    }
  }
  & > .itemIsInHome {
    color: var(--color-on-bg-primary);
    .anchorlink {
      color: var(--color-on-bg-primary);
    }
  }
}
.item {
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
  .anchorlink {
    text-decoration: none;
  }
  @media screen and (min-width: $breakpoint-md) {
    margin-right: 2.4rem;
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-right: 4.8rem;
  }
  @include label-l-medium;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    transition: height 0.2s;
    background-color: var(--color-primary);
  }
  color: var(--color-on-bg-primary);
  .anchorlink {
    color: var(--color-on-bg-primary);
  }
  transition: color 0.2s;
  &::before {
    height: 0%;
    transition-duration: 0.2s;
  }
}
/* 
.label {
}
.buttonItem {
  position: relative;
  text-transform: uppercase;
  height: 100%;
  cursor: pointer;
  @include label-l-medium;
}
.arrow {
  height: 0.8rem;
  width: 1.6rem;
  margin-left: auto;
  margin-left: 0.8rem;
}
.arrowUp {
  transition-duration: 0.2s;
  transform: rotate(180deg);
}
.arrowDown {
  transform: rotate(0deg);
  transition-duration: 0.2s;
} */
