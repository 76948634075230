@import 'styles/core/typography';

.container {
  position: fixed;
  top: 0;
  width: 0;
  height: 100%;
  right: 0;
  transition: all 0.3s ease;
  z-index: 100;
  background-color: var(--color-bg);

  .close {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem;
    background-color: #000;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &.sticky {
    top: 6rem;
    @media screen and (min-width: $breakpoint-md) {
      top: 8.2rem;
    }
  }
}

.isOpen {
  width: 100%;
  right: 0;

  @media screen and (min-width: $breakpoint-md) {
    width: 650px;
  }
}
