@import 'styles/utils/mixins';
@import 'styles/core/typography';

.accordionMenu {
  background-color: var(--color-surface-01);
  padding: 3.6rem 1.6rem 0;

  @media screen and (min-width: $breakpoint-sm) {
    padding: 4rem 2.8rem 0rem;
  }
  @media screen and (min-width: $breakpoint-lg) {
    display: none;
    background-position-y: -68%;
    background-size: 134%;
  }
  height: calc(100vh - 6.2rem);
  position: absolute;
  z-index: -1;
  transform: translateX(-100%);
  overflow: hidden;
  transition: all 0.2s linear;
}
.show {
  position: absolute;
  width: 100%;
  z-index: 99999;
  transform: translateX(0%);
  overflow: auto;
  transform-origin: left;
  transition: all 0.2s linear;
  @media screen and (min-width: $breakpoint-sm) {
    height: calc(100vh - 6.2rem);
  }
}

.itemContainer {
  padding: 2.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 0.1rem solid var(--color-divider-primary);
  position: relative;
  z-index: 7;
}
.btnAccordion {
  display: flex;
  align-items: center;

  flex-grow: 1;
}

.item {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-on-bg-primary);
  transition: padding-left 0.2s;
  padding-left: 0rem;
  @include label-l-medium;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0rem;
    height: 0rem;
    transition: width 0.2s;
  }
}
.itemActive {
  color: var(--color-on-bg-primary);
  position: relative;
  padding-left: 0.8rem;
  transition: padding-left 0.2s;
  &::before {
    width: 0.4rem;
    height: 1.6rem;
    background-color: var(--color-primary);
    transition: width 0.2s;
  }
}
.itemInActive {
  color: var(--color-on-bg-secondary);
  &::before {
    top: 0;
    width: 0rem;
    transition: width 0.2s;
  }
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  transform-origin: top;
}

.open {
  padding-top: 2.8rem;
  overflow: visible;
  height: 100%;
  max-height: auto;
  transition: padding-top, height, transform, overflow-y;
  transition-duration: 0.2s;
}
.close {
  padding-top: 0rem;
  height: 0%;
  max-height: 0rem;
  overflow: hidden;
  transition: padding-top, height, transform, overflow-y;
  transition-duration: 0.2s;
}
.link {
  text-decoration: none;
  color: var(--color-on-bg-primary);
  @include heading-m-medium;
  &:not(:last-of-type) {
    padding-bottom: 2.4rem;
  }
}
.itemDefault {
  color: var(--color-on-bg-primary);
}
.arrow {
  height: 0.8rem;
  width: 1.6rem;
  margin-left: auto;
}
.arrowUp {
  transition-duration: 0.2s;
  transform: rotate(180deg);
}
.arrowDown {
  transform: rotate(0deg);
  transition-duration: 0.2s;
}
.inEvidence {
  margin-top: 4rem;
  display: block;
  color: var(--color-on-bg-secondary);
  margin-bottom: 1.6rem;
  &::first-letter {
    text-transform: capitalize;
  }
  @include body-xs-medium;
}

.logo {
  transform: scale(240%);
  object-position: center 95%;
  z-index: -1;
}
