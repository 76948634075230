.button {
  cursor: pointer;
}

@import 'styles/utils/shared';

.overlay {
  background-color: rgba(15, 19, 21, 0.8);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999999;
}

.content {
  background-color: var(--color-surface-01);
  border-radius: var(--space-size-0-5);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999999;
  padding: var(--space-size-2) var(--space-size-2) var(--space-size-3);

  @media screen and (min-width: $breakpoint-md) {
    padding: 2.2rem var(--space-size-3);
    border: 0.1rem solid var(--color-surface-03);
    box-shadow: 0 var(--space-size-0-5) 2rem rgba(0, 0, 0, 0.6);
    border-radius: var(--space-size-1);
  }
}

.content:focus {
  outline: none;
}
@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeBtn {
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: var(--space-size-3);
  height: var(--space-size-3);
  padding: 0.5rem;
}
