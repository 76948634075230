@import 'styles/core/typography';

.container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-surface-03);
  width: 1.25em;
  height: 1.25em;
}

.messages {
  color: var(--color-accent);
  @include body-xs;
}
