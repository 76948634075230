@import 'styles/core/typography';

.container {
  color: var(--color-accent);
  background-color: var(--color-bg);
  padding: var(--space-size-1) 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2rem;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 1.4rem;
  }

  strong {
    margin: 0 0 0 var(--space-size-0-5);
  }
}
