@import 'styles/utils/shared';
.button {
  &[disabled] {
    opacity: 0.5;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  @media screen and (min-width: $breakpoint-lg) {
    top: 0.4rem;
  }
}

.iconContainer {
  position: relative;
  width: var(--space-size-3);
  height: var(--space-size-3);
}
.icon {
  width: var(--space-size-3);
  height: var(--space-size-3);
  position: absolute;
  top: 0;
  left: 0;
}
