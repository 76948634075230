@import 'styles/utils/mixins.scss';
@import 'styles/core/typography.scss';
@import 'styles/core/buttons.scss';

.aside {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  &.visible {
    visibility: visible;
    opacity: 1;
  }
}
.openButton {
  @include button-tertiary-normal;
  cursor: pointer;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  position: fixed;
  bottom: var(--space-size-3);
  padding: var(--space-size-2) var(--space-size-1) var(--space-size-2) var(--space-size-2);
  display: flex;
  align-content: stretch;
  align-items: center;
  z-index: 3;

  @media screen and (min-width: $breakpoint-md) {
    bottom: var(--space-size-6);
  }
}
.openButtonDisabled {
  opacity: 0.5;
  cursor: default;
}

.instantIcon {
  width: var(--space-size-5);
  height: var(--space-size-5);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.instantIcon:hover {
  animation: rotation 1.5s linear infinite;
}
.panelVisible {
  transform: translateX(0);
  transition: transform 250ms ease-in-out;
}
.panelHidden {
  transform: translateX(-100%);
  transition: transform 250ms ease-in-out;
}
.container {
  background-color: var(--color-surface-01);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media screen and (min-width: $breakpoint-sm) {
    max-width: 36.8rem;
  }
}

.tabs {
  @include body-medium;
  padding: var(--space-size-2) 0 0 0;
  color: var(--color-on-bg-secondary);
  background-color: var(--color-bg);
  text-transform: capitalize;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}

.tab {
  color: inherit;
  white-space: nowrap;
  width: auto;
  cursor: pointer;
}

.activeTitle {
  border-bottom: 0.4rem solid var(--color-primary);
  padding: 0 0 var(--space-size-2) 0;
  color: var(--color-on-bg-primary);
  @include body-bold;
}

.closeIcon {
  width: var(--space-size-1-5);
  height: var(--space-size-1-5);
  cursor: pointer;
}

.contentSection {
  padding: 0 var(--space-size-3) 0 var(--space-size-3);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @include grid;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.4;
}

.rouletteContent {
  grid-column: 1 / span 6;
  margin-top: var(--space-size-3);
  transition: opacity 200ms, display 200ms;
  display: none;
  opacity: 0;
  position: relative;
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 1 / span 12;
  }
}
.slotContent {
  margin-top: var(--space-size-3);
  align-self: flex-start;
  transition: opacity 200ms, display 200ms;
  display: none;
  opacity: 0;
  position: relative;
  aspect-ratio: 0.45%;
  grid-column: 1 / span 6;
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 1 / span 12;
  }
}

.activeContent {
  display: grid;
  opacity: 1;
}

.iframe {
  align-self: center;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.cardListContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);
  grid-gap: var(--space-size-2);

  article {
    grid-column: span 1;
  }
}

.hideButton {
  @include button-tertiary-small;
  @include label-s-medium;
  cursor: pointer;
  align-self: center;
  margin-bottom: var(--space-size-3);
  margin-top: var(--space-size-3);
  width: fit-content;
}

.hideIcon {
  margin-right: var(--space-size-1-5);
  width: var(--space-size-1);
  height: var(--space-size-1);
}

.spinner {
  width: unset;
  height: unset;
}
