@import 'styles/core/typography';
@import 'styles/core/buttons';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto 2rem 2rem 2rem;

  @media screen and (min-width: $breakpoint-md) {
    height: 100%;
    margin: 0 0 4.6rem 0;
  }

  .icon {
    width: var(--space-size-6);
    height: var(--space-size-6);
    margin: 0.2rem;
  }

  .icon32 {
    width: var(--space-size-4);
    height: var(--space-size-4);
  }

  .title {
    @include label-m-bold;
    color: var(--color-on-bg-primary);
    text-transform: uppercase;
    text-align: center;
    margin: var(--space-size-1-5) 0;

    @media screen and (min-width: $breakpoint-md) {
      @include heading-l-medium;
      margin: var(--space-size-2) var(--space-size-3);
      text-transform: initial;
    }
  }

  .text {
    @include body-medium;
    color: var(--color-on-bg-secondary);
    text-align: center;

    @media screen and (min-width: $breakpoint-md) {
      margin: 0 13.3rem 0 13.3rem;
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin: 0 16.6rem 0 16.6rem;
    }
  }

  .info {
    @include body-medium;
    color: var(--color-on-bg-primary);
    margin-top: var(--space-size-2);
  }
}

.buttonsDialog {
  display: grid;
  width: 100%;
  gap: var(--space-size-2);
  margin: 10rem 0 0 0;
  @media screen and (min-width: $breakpoint-md) {
    grid-template-columns: auto auto;
    width: unset;
    margin: 4rem 0 0 0;
  }
}
