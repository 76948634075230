@mixin grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1.6rem;

  @media screen and (min-width: $breakpoint-md) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.6rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 2.4rem;
  }
}

@mixin container {
  padding-left: var(--pad-container-xs);
  padding-right: var(--pad-container-xs);

  @media screen and (min-width: $breakpoint-sm) {
    padding-left: var(--pad-container-sm);
    padding-right: var(--pad-container-sm);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-left: var(--pad-container-lg);
    padding-right: var(--pad-container-lg);
  }
}

@mixin truncate-text($numOfRown) {
  display: -webkit-box;
  -webkit-line-clamp: $numOfRown;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
@mixin max-width-body() {
  max-width: var(--max-width-body);
}

@mixin animationLoading($animationDuration: 1.5s, $animationDelay: 0s) {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  opacity: 0.4;
  background-image: linear-gradient(90deg, var(--color-surface-02), var(--color-surface-01), var(--color-surface-02));
  transform: translateX(-100%);
  animation-name: loader;
  animation-direction: normal;
  animation-duration: $animationDuration;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: $animationDelay;
}
@mixin label-registration {
  @include label-s-bold;
  color: var(--color-on-bg-secondary);
  margin: 0 0 var(--space-size-1) 0;
  text-transform: uppercase;
}
