@import 'styles/core/typography';

.container {
  background-color: var(--color-surface-02);
  width: 100%;
  height: 100%;
  border-radius: var(--space-size-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.icon {
  height: var(--space-size-3);
  width: var(--space-size-3);
}

.text {
  @include label-xs-medium;
  color: var(--color-on-bg-secondary);
  text-transform: uppercase;
  max-width: var(--space-size-10);
  text-align: center;
  margin-top: var(--space-size-1-5);
}

.logo {
  transform: scale(270%);
  object-position: 50% 37%;
}
