@import 'styles/core/typography';

.container {
  @include label-xs-bold;
  color: var(--color-bg);
  background-color: var(--color-accent);
  padding: var(--space-size-0-5) 0;
  text-align: center;
  font-weight: var(--text-weight-bold);
  text-transform: uppercase;
}
