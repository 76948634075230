@import 'styles/core/typography';
@import 'styles/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.description {
  @include truncate-text(2);
  @include body-xs-medium;
  color: var(--color-on-bg-secondary);
}
