@import 'styles/utils/shared';

.container {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 var(--space-size-1) 0 0;
  background-color: var(--color-neutral-02);
  border: 0.1rem solid var(--color-neutral-01);

  @media screen and (min-width: $breakpoint-lg) {
    width: 3.4rem;
    height: 3.4rem;
  }
}

.link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: var(--space-size-2);
  height: var(--space-size-2);
}
