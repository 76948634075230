@import 'styles/utils/mixins';
@import 'styles/core/buttons';
@import 'styles/core/typography';

.header {
  position: relative;
  z-index: 3;
  width: 100%;
  border-top: 0.1rem solid var(--color-divider-primary);
  border-bottom: 0.1rem solid var(--color-divider-primary);
  background-color: var(--color-surface-01);
}
.headerOnlyLogo {
  position: relative;
  z-index: 3;
  width: 100%;
  border-top: 0.1rem solid var(--color-divider-primary);
  border-bottom: 0.1rem solid var(--color-divider-primary);
  background-color: var(--color-surface-01);
  display: flex;
  justify-content: center;
  @media screen and (min-width: $breakpoint-xl) {
    display: flex;
    justify-content: center;
  }
}

.wrapper {
  display: flex;
  height: 6rem;

  @media screen and (min-width: $breakpoint-md) {
    min-width: 100%;
    max-width: 100%;
  }

  @media screen and (min-width: $breakpoint-lg) {
    height: 5.8rem;
  }

  @media screen and (min-width: $breakpoint-xl) {
    height: 7.6rem;
    min-width: 100%;
    max-width: 100%;
    padding-right: 0;
    margin: 0 auto;
  }
}

.wrapperOnlyLogo {
  display: flex;
  justify-content: center;
  @media screen and (min-width: $breakpoint-lg) {
    @include container;
  }
  @media screen and (min-width: $breakpoint-xl) {
    min-width: 100%;
    max-width: 100%;
    padding-right: 0;
    margin: 0 auto;
  }
}

.firstCol {
  display: flex;
  align-items: center;
  padding: 0 0 0 var(--space-size-2);
  flex: 1 1 auto;

  @media screen and (min-width: $breakpoint-sm) {
    padding: 0 0 0 var(--space-size-3);
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding: 0 0 0 var(--space-size-5);
  }

  @media screen and (min-width: $breakpoint-xl) {
    padding: 0 0 0 var(--space-size-5);
  }
}

.logoContainer {
  position: relative;
  cursor: pointer;
  width: 8rem;
  aspect-ratio: 4;
  margin: 0 0.6rem;

  @media screen and (min-width: $breakpoint-sm) {
    width: 9.68rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    margin: 0 2rem 0 0.6rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: 0 var(--space-size-3) 0 0.6rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: var(--space-size-1) 16.2rem var(--space-size-1) 0.6rem;
  }
}

.menuIconContainer {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 var(--space-size-0-5) 0 0;

  @media screen and (min-width: $breakpoint-sm) {
    margin: 0 var(--space-size-2) 0 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}
.menuIcon {
  width: var(--space-size-3);
  height: var(--space-size-3);
  padding: 0 0.2rem;
}

.buttonsListContainer {
  opacity: 0;
  z-index: -1;
  display: flex;

  transition: opacity;
  transition-delay: 1s;
  transition-duration: 1s;

  &[data-visible='1'] {
    opacity: 1;
    z-index: unset;
  }
}

.buttonsList {
  display: flex;
  align-items: center;
  margin-right: unset;
  height: 100%;
  border-left: 0.1rem solid var(--color-divider-primary);
}

.btnLink {
  height: 100%;
  background-color: transparent;
  color: var(--color-on-bg-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.2s;
  padding: 0 2.4rem;
  border-left: 0.1rem solid var(--color-divider-primary);

  &:hover,
  &:focus {
    transition: background-color 0.2s;
    background-color: var(--color-bg);
  }
  @media screen and (min-width: $breakpoint-sm) {
    padding: 0 2.4rem;
    min-width: auto;
  }
}

.linkLabel {
  display: none;
  @media screen and (min-width: $breakpoint-sm) {
    display: block;
    color: var(--color-on-bg-primary);
    text-transform: uppercase;
    margin-left: 0.8rem;
    @include label-l-medium;
  }
}
.icon {
  width: 2.4rem;
  height: 2.4rem;
}
.btnLogin {
  text-transform: uppercase;
  background-color: var(--color-primary);
  color: var(--color-neutral-02);
  transition: background-color 0.2s;
  padding: 1.6rem;
  cursor: pointer;
  height: 100%;
  width: 7.9rem;
  &:hover,
  &:focus {
    transition: background-color 0.2s;
    background-color: var(--color-primary-hover);
  }

  @media screen and (min-width: $breakpoint-sm) {
    padding: 0 var(--space-size-1);
    width: 13rem;
  }
  @include label-m-bold;
}

.container {
  transform: translateY(0%);
  transition: transform 0.2s ease-in, opacity 0.2s ease-in 0.3s;
  opacity: 1;

  position: -webkit-sticky;
  position: sticky;

  top: 0;
  width: 100%;
  z-index: 10000;

  &.scrolledUp {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.3s ease-in;
  }

  &.scrolledDown {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in, opacity 0s ease-in 0.3s;
    opacity: 0;
  }
}
